import React from 'react';
import styled from 'styled-components';
import className from 'classnames';

import EnLogin from '../components/en_login';
import LoadingWrapper from '../components/loadingWrapper';
import constant from '../constant';
import SEO from "../components/seo";
import withI18next from '../components/withI18next';
import Alert from '../components/alert';
import GtVerifyContainer from './gtc';
import { isBrowser } from '../utils';
import {
  getRegCode,
  getLoginCode,
  getForgetCode,
  register,
  login,
  setToken,
  forget,
  resetPassword,
} from "../http";
import '../components/layout.css';

const LoginContainer = styled.div`
  margin: 0 auto;
  max-width: 1920px;
  max-height: 1080px;
  height: 100vh;
  .left-side {
    flex: 0 0 420px;
    background: ${constant.productBg};
    @media(max-width: 750px) {
      display: none !important;
    }
    .item {
      width: 254px;
      animation-duration: 3s;
      animation-fill-mode: both;
      animation-name: fadeIn;
      @keyframes fadeIn {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
      .feature-img {
        width: 260px;
        height: 200px;
        margin-bottom: 34px;
        object-fit: contain;
      }
      h2 {
        font-size: 20px;
        color: ${constant.subTitle};
        line-height: 36px;
        margin-bottom: 18px;
      }
      p {
        font-size: 14px;
        color: ${constant.subTitle};
        white-space: pre;
        line-height: 26px;
      }
    }
  }
  .mana {
    flex: 1 1 1499px;
    .mana-container {
      width: 400px;
      position: relative;
      @media (max-width: 750px) {
        width: 375px;
      }
      /* .input-group {
        width: px;
      } */
      .logo {
        
      }
      .alert {
        position: absolute;
      }
    }
    .tabs {
      margin-top: 60px;
      .list-group {
        border-bottom: 2px solid ${constant.desc};
        .list-group-item {
          color: ${constant.desc};
          padding: 0;
          font-size: 20px;
          height: 46px;
          line-height: 46px;
          cursor: pointer;
          border: none;
          border-radius: none;
          &.active {
            box-sizing: border-box;
            color: ${constant.subTitle};
            background: none;
            border-bottom: 3px solid ${constant.active};
            border-radius: 0;
            transform: translateY(1.5px);
          }
        }
      }
    }
    .fields {
      .input-group {
        border-radius: 22px;
        border: 1px solid #DDE1E6;
        overflow: hidden;
        margin-bottom: 24px;
        position: relative;
        &:first-child {
          margin-top: 60px;
        }
        .input-group-prepend {
          position: relative;
          border-right: 1px solid #DDE1E6;
          background: #F6F9FC;
          width: 50px;
          height: 45px;
          .input-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 30px;
            margin: auto;
            height: 30px;
            border: none;
          }
        }
        input {
          border: none;
          border-left: 1px solid #dde1e6;
          text-indent: 1em;
        }
        input + i.corrected {
          position: absolute;
          width: 12px;
          height: 12px;
          right: 20px;
          top: 50%;
          background: center / contain no-repeat;
          transform: translateY(-50%);
          background-image: none;
        }
        input:invalid +i.corrected {
          background-image: url(${constant.wrong});
        }
        input:valid +i.corrected {
          background-image: url(${constant.correct});
        }
        .get-code {
          position: absolute;
          right: 2px;
          top: 0;
          bottom: 0;
          margin: auto;
          padding: 0;
          width: 126px;
          height: 36px;
          line-height: 36px !important;
          background: #DDE1E6;
          border: none;
          border-radius: 22px;
          color: #626F86;
          font-size: 16px;
          box-shadow: unset;
          outline: none;
          cursor: pointer;
          &.disable {
            color: #dedede;
            pointer-events: none;
            background: white;
            cursor: normal;
          }
        }
        .see-password {
          position: absolute;
          right: 20px;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 30px;
          height: 30px;
          background: center / 30px 30px no-repeat;
        }
      }
      .status-link {
        color: ${constant.active};
        font-size: 16px;
        cursor: pointer;
      }
    }
    .submit {
      display: block;
      margin: 0 auto;
      width:183px;
      height:43px;
      border-radius:24px;
    }
    .other {
      font-size: 16px;
      color: #8992BF;
      padding: 0 10px;
      .forget {
        cursor: pointer;
        
      }
      input[type=checkbox] {
        border: 1px solid #DDE1E6;
        margin-right: 1em;
      }
    }
  }
`;

const initState = {
  activeCur: 0,
  status: 0,
  verify: false,
  mobile: '',
  password: '',
  repeat_password: '',
  code: '',
  cd: 0,
  alert: '',
  captchaObj: '',
  captchaVerify: false,
  captchaData: '',
  loading: false
}

// 0 登录 1 注册 2 手机验证码登录 3 忘记密码 4 重置密码 5 邮箱注册

class Login extends React.Component {
  static item = ['login', 'register', 'verify', 'forget', 'reset', 'mail' ]
  static schema = {
    mobile: mob => /^1[3-9](\d{9})$/.test(mob),
  }
  static PasswordInput = (props) => {
    const [visible, setVisible] = React.useState(false)
    return (
      <React.Fragment>
        <input
          className="flex-grow-1"
          type={visible ? "text" : props.type}
          onChange={e => props.onChange(e)}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          pattern={props.pattern}
          onInvalid={(e) => e.target.setCustomValidity('密码应为8-16位(不包含空格)')}
        />
        <i
          className="see-password"
          style={{ backgroundImage: `url(${visible ? constant.eye : constant.eye_c})` }}
          onClick={() => setVisible(!visible)}
        />
      </React.Fragment>
    );
  }
  constructor(props) {
    super(props);
    const { hash } = props.location;
    const status = parseInt(hash.slice(1)) || 0;
    this.state = { ...initState, status, };
    this.timer = setInterval(() => {
      const { activeCur } = this.state;
      this.setState({
        activeCur: activeCur === 3 ? 0 : activeCur+1
      });
    }, 10000);
    
    if (isBrowser() && window.localStorage.token) {
      window.location = '/';
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  componentDidMount() {
    const isEn = window.location.href.split("/").includes("en");
    if (isEn) {
      window.localStorage.setItem("lng", "en")
    } else {
      window.localStorage.setItem("lng", "zh")
    }
  }

  alert(message, type) {
    this.setState(
      { alert: { type, message } },
      () => setTimeout(() => this.setState({ alert: '' }), 2000));
  }

  checkValid(name, val) {
    const test = Login.schema[name];
    return test(val);
  }
  
  changeState(name, val) {
    if (name === 'status') {
      this.setState({
        [name]: val,
        password: '',
        repeat_password: '',
      })
    }
    this.setState({ [name]: val });
  }

  handleChange({ currentTarget: target }) {
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  verifySuccess(data, captchaObj) {
    this.setState({
      captchaVerify: true,
      captchaData: data,
      captchaObj,
    });
  }

  getCode() {
    const { status, mobile, cd, captchaVerify } = this.state;
    const valid = this.checkValid('mobile', mobile) && captchaVerify;
    if (valid && !cd) {
      const handleCount = (res) => {
        if (res.status === 0) {
          const time = setInterval(() => {
            const { cd } = this.state;
            if (cd > 0) {
              this.setState({ cd: cd - 1, });
            }
            else {
              clearInterval(time);
            }
          }, 2000);
          this.setState({ time, cd: 120 });
        }
        else {
          this.alert(res.msg, 'warning');
        }
      };
      switch (status) {
        case 1:
          getRegCode(mobile)
            .then(handleCount)
            .catch(
              (err) => {
                this.alert(err.data.msg, 'danger')
              }
            )
          break;
        case 2:
          getLoginCode(mobile)
            .then(handleCount)
            .catch(
              (err) => {
                this.alert(err.data.msg, 'danger')
              }
            )
          break;
        case 3:
          getForgetCode(mobile)
            .then(handleCount)
            .catch(
              (err) => {
                this.alert(err.data.msg, 'danger')
              }
            );
          break;
        default:
          break;

      }
      
    } else {
      this.alert('请确认手机号输入正确并且已完成验证', 'warning');
    }
  }
  handleError(res) {
    this.setState({ loading: false });
    const { captchaObj } = this.state;
    this.alert(res.msg, 'danger');
    captchaObj.reset();
  }

  handleLogin(res) {
    this.setState({ loading: false });
    if (res.status === 0) {
      setToken(res.data);
      window.location = '/';
    } else {
      this.handleError(res);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      status,
      code,
      mobile,
      password,
      repeat_password,
      captchaData,
      captchaVerify,
      // captchaObj,
    } = this.state;
    if (!captchaVerify) {
      this.alert('请先完成验证', 'warning');
      return;
    } else {
      this.setState({
        loading: true
      })
    }
    switch (status) {
      case 0:
        login({ mobile, password, login_type: 2, ...captchaData })
          .then((res) => this.handleLogin(res));
        break;
      case 1:
        register({ mobile, code, password, ...captchaData })
          .then(res => {
            if (res.status === 0) {
              this.setState({ loading: false });
              this.alert('注册成功，请登录。', 'success');
              this.changeState('status', 0);
            } else {
              this.handleError(res);
            }
          });
        break;
      case 2:
        login({ mobile, code, login_type: 1, ...captchaData })
          .then(res => this.handleLogin(res));
        break;
      case 3:
        forget({ mobile, code, ...captchaData })
          .then(res => {
            this.setState({ loading: false });            
            if (res.status === 0) {
              this.changeState('status', 4);
            } else {
              this.handleError(res);
            }
          });
        break;
      case 4:
        if (repeat_password !== password) {
          this.alert('请先确保两次输入的密码相同！', 'warning');
          this.setState({ loading: false });
          return;
        }
        resetPassword({ mobile, code, password, ...captchaData })
          .then(res => {
            if (res.status === 0) {
              this.alert('重置成功，请登录', 'success');
              this.setState({ loading: false });
              this.changeState('status', 0);
            } else {
              this.handleError(res);
            }
          });
      default:
        break;
    }
  }

  render() {
    const { t, locale } = this.props;
    const { status, cd, mobile, captchaVerify, alert, activeCur, loading } = this.state;
    if (locale === 'en') return <EnLogin t={t} status={status} />;
    
    const viewSet = t(`login.${Login.item[status]}`, { returnObjects: true });
    const s = t('login.title', { returnObjects: true });
    // [fIdx];

    return (
      <LoginContainer className="d-flex">
        <SEO title="login" />
        {alert && <Alert info={alert} />}
        <div className="left-side d-flex justify-content-center align-items-center">
          {/* {s.map((it,idx) => ( */}
          <div className="item text-center" key={s[activeCur].name}>
            <img className="feature-img" src={constant.features[activeCur]} alt=""/>
            <h2><img src={constant.feature_icons[activeCur]} alt=""/> {s[activeCur].name}</h2>
            <p>{s[activeCur].desc}</p>
          </div>
          {/* ))} */}
          {/* <div className="item text-center">
            <img className="feature-img" src={constant.features[fIdx]} alt=""/>
            <h2><img src={constant.feature_icons[fIdx]} alt=""/> {s.name}</h2>
            <p>{s.desc}</p>
          </div> */}
        </div>
        <div className="mana d-flex justify-content-center align-items-center">
          <form onSubmit={(e) => this.handleSubmit(e)} className="mana-container">
            <div className="logo d-flex justify-content-center align-items-center">
              <a href="/">
                <img height="80" src={constant.logo} alt=" "/>
              </a>
            </div>
            <div className={className('form-container', { loading })} >
              <div className="tabs">
                <ul className="list-group list-group-horizontal text-center">
                  {viewSet.tabs.map(tab => (
                    <li
                      className={className('list-group-item flex-grow-1', { active: (status === tab.status) || (
                          Array.isArray(tab.status) && tab.status.indexOf(status) !== -1
                        )
                      })}
                      key={tab.status}
                      onClick={
                        () => typeof tab.status !== undefined && (this.changeState('status', Array.isArray(tab.status) ? tab.status[0] : tab.status))
                      }
                    >{tab.label}
                    </li>)
                  )}
                </ul>
              </div>
              <div className="fields">
                {viewSet.filed.map(it => (
                  it.name === 'verification' ? <GtVerifyContainer lang={locale} verifySuccess={(data, captchaObj) => this.verifySuccess(data, captchaObj)} /> :
                    <div className="input-group" key={it.label}>
                      <div className="input-group-prepend">
                        <img className="input-icon" src={constant[it.icon]} alt=""/>
                        {/* <span className="input-group-text">{it.label}</span> */}
                      </div>
                      {it.type === "password" ? (
                        <Login.PasswordInput
                          name={it.name}
                          placeholder={it.placeholder}
                          onChange={e => this.handleChange(e)}
                          value={this.state[it.name]}
                          type={it.type}
                          pattern={it.pattern}
                        />
                      ) : (
                        <input
                          className="flex-grow-1"
                          onChange={e => this.handleChange(e)}
                          value={this.state[it.name]}
                          {...it}
                        />
                      )}
                      {
                        it.name === 'mobile' && <i className={className({ corrected: this.state.mobile })} />
                      }
                      {
                        it.name === "code" && (
                          <div
                            className={className("btn btn-secondary get-code", { disable: !captchaVerify || cd || !this.checkValid('mobile', mobile) })}
                            onClick={() => this.getCode()}
                          >{cd ? t('login.cd', { cd }) : it.get}
                          </div>
                        )
                      }
                    </div>
                ))}
                {viewSet.link && (
                  <p
                    onClick={() => this.changeState('status', viewSet.link.status)}
                    className="status-link text-center"
                  >{viewSet.link.label}
                  </p>
                )}
              </div>
            </div>
            {status !== 3 && (
              <div className="other d-flex justify-content-between">
                <label>
                  <input type="checkbox" />
                  {t('login.remember')}
                </label>
                <span
                  className="forget"
                  onClick={() => this.changeState('status', 3)}
                >{t('login.forget_text')}?
                </span>
              </div>
            )}
            <button
              className="submit web-button btn btn-primary"
            >{viewSet.button}
            </button>
            {loading && <LoadingWrapper />}
          </form>
        </div>
      </LoginContainer>
    )
  }
}

export default withI18next({ ns: 'common' })(Login);