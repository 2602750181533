import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';

import { host } from '../http';

// const url = 'https://ljx.heeuser.fishiny.com/';

const GtContainer = styled.div`
  /* width: 400px; */
  height: 44px;
  margin-bottom: 20px;

  // border-radius: 22px;
  // border: 1px solid #DDE1E6;
  .geetest_radar_btn {
    border-radius: 22px !important;
  }
`;
class GtVerifyContainer extends Component {
  
  static propTypes = {
    verifySuccess: PropTypes.func.isRequired,
  };
  constructor() {
    super();
    this.myCaptcha = React.createRef();
    this.myText = React.createRef();
    this.state = {
      captcha: '',
    };
  }
  componentDidMount() {
    const { lang } = this.props;
    axios(`${host}/user/v1/gtcaptcha/start_captcha_servlet?t=${(new Date()).getTime()}`)
      .then((res) => {
        const data = res.data.data;
        this.setState({
          captcha: data,
        });
        window.initGeetest({
          gt: data.gt,
          lang: lang === 'en' ? 'en' : 'zh-cn',
          challenge: data.challenge,
          new_captcha: data.new_captcha,
          product: 'float', // 产品形式，包括：float，embed，popup。注意只对PC版验证码有效
          offline: !data.success, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
          width: '100%',
          // 更多配置参数请参见：http://www.geetest.com/install/sections/idx-client-sdk.html#config
        }, captchaObj => this.handlerEmbed(captchaObj));
      });
  }
  handlerEmbed(captchaObj) {
    captchaObj.appendTo('#captcha');
    captchaObj.onReady(() => {
      this.myText.current.classList.add('hide');
    });
    captchaObj.onSuccess(() => {
      // console.log(e);
      const res = captchaObj.getValidate();
      // console.log(res);
      this.props.verifySuccess(Object.assign(this.state.captcha, res), captchaObj);
    });
  }
  render() {
    const { lang } = this.props;
    let load = '行为验证™ 安全组件加载中';
    if (lang === 'en') {
      load = 'loading';
    }
    return (
      <GtContainer>
        <div id="captcha" className="tc" ref={this.myCaptcha}>
          <div className="tc" ref={this.myText}>{load}</div>
        </div>
      </GtContainer>
    );
  }
}

export default (GtVerifyContainer);
